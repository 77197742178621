import { IDECONSTANT } from '@/utils/ide'

import editorService from '@/services/ide/editor.service'
import liveCodingService from '@/services/ide/liveCoding.service'
import projectsService from '@/services/ide/projects.service'
import recaptchaService from '@/services/recaptcha.service'
import utilModelsService from '@/services/util.models.service'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import {
  LiveCodeActionsType,
  useLiveCodeStore,
  type ILiveCodeActions
} from '@/stores/liveCode.store'
import { SYNC_ACTIONS } from '@/utils/ide'
import axios from 'axios'
import { cloneDeep } from 'lodash-es'
export interface ISyncRequest {
  id?: string
  projectKey?: string | boolean
  name?: string
  path?: string
  toPath?: string
  fromPath?: string
  type?: string
  oldName?: string
  parent?: string
  content?: string
  lang?: string
}

/**
 * @param data - taking the data of the uploaded file
 * @param items - taking the data of the current project
 * @param action - taking the action to be performed
 * @returns the updated project data
 */
const uploadFileForLiveCodingSync = async (
  data: any,
  items: any,
  action: string
): Promise<undefined> => {
  if (data.tempParent == '/' || '/' + items.name == data.tempParent) {
    if (!items.children) {
      items.children = []
    }
    data.isDirty = true
    if (!data.dirtyActions) {
      data.dirtyActions = []
    }
    //find item already exists in children
    const duplicateIndex = items.children.findIndex((o: any) => o.name === data.name)
    if (duplicateIndex > -1) {
      delete data.tempParent
      items.children[duplicateIndex].isDirty = true
      items.children[duplicateIndex].dirtyActions.push({ action: action })
    } else {
      data.dirtyActions.push({ action: action })
      delete data.tempParent
      items.children.push(cloneDeep(data))
    }
    return
  } else if (items.children) {
    try {
      let parents =
        data?.tempParent?.split('/')?.length > 0 ? data?.tempParent?.split('/') : [data.parent]
      parents = parents.filter((o: string) => o !== '')

      const child = items.children.find((o: any) => o.name === parents[0])
      if (child) {
        if (parents.length > 1) data.tempParent = data.tempParent.replace('/' + parents[0], '')
        return uploadFileForLiveCodingSync(data, child, action)
      }
    } catch (e) {
      ;() => {}
    }
  }
}

/**
 * Upload file to server
 * @param inputElement - The input element
 * @param item - The item to upload
 * @param itemParent - The parent of the item
 * @param duplicate - The duplicate item
 * @param sync - The sync flag
 */
const uploadFileToServer = async (
  inputElement: HTMLInputElement,
  item: any,
  itemParent: string,
  duplicate: any,
  sync: boolean = true
) => {
  if (!inputElement?.files || !inputElement.files[0]) return
  const uploadedFile = inputElement.files[0]
  const formData = new FormData()
  formData.append('projectKey', useIdeStore().projectKey as string)
  formData.append('path', itemParent)
  formData.append('file', uploadedFile)
  await axios
    .post('/api/projectSync/uploadFile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response: { data: { name: string } }) => {
      const reader = new FileReader()
      /**
       * read the file and add it to the tree
       * @param e - The event
       */
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        if (duplicate) {
          duplicate.content = e.target?.result
          if (
            useIdeStore().activeItem.name === duplicate.name &&
            useIdeStore().activeItem.parent === duplicate.parent
          ) {
            editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, duplicate.content)
            editorService.heightChangeFunction(IDECONSTANT.CODE_EDITOR)
          }
        } else {
          item.children.push({
            name: response.data.name,
            content: e.target?.result,
            editMode: false,
            markedForDeletion: false,
            parent: itemParent
          })
        }
        await projectsService.autoSave()
        if (sync) {
          const liveCodeAction: ILiveCodeActions = {
            type: LiveCodeActionsType.SYNC_UPLOAD_PROJECT_FILE,
            data: {
              name: response.data.name,
              content: e.target?.result,
              editMode: false,
              markedForDeletion: false,
              parent: itemParent,
              tempParent: itemParent
            }
          }
          liveCodingService.broudcastMessage(liveCodeAction)
        }
      }
      reader.readAsText(uploadedFile)
      useIdeStore().setCodeUpdated(true)
    })
    .catch((error: { response: { status: number; data: { errors: Array<string> } } }) => {
      if (error?.response?.status === 403) {
        useAuthStore().clearRobotCheck()
        useIdeStore().setSyncErrorMessages('Unable to upload, please try again.')
      } else {
        useIdeStore().setSyncErrorMessages(error?.response?.data?.errors[0])
      }
    })
}
/**
 * Upload file
 * @param target - The target
 * @param item - The item to upload
 */
const uploadFile = async (target: HTMLInputElement, item: any) => {
  if (!target || !target.files || !item) {
    useIdeStore().setSyncErrorMessages('Please try again!')
    return
  }
  await recaptchaService
    .callViaCaptcha()
    .then(async () => {
      let itemParent: string = '/'
      if (item.parent && item.parent === '/') {
        itemParent = '/' + item.name
      } else if (item.parent) {
        itemParent = item.parent + '/' + item.name
      }

      let duplicate: any = null
      for (const child of item.children) {
        if (target.files && target.files[0] && child.name === target.files[0].name) {
          duplicate = child
          break
        }
      }
      if (duplicate) {
        if (duplicate?.children) {
          await utilModelsService.alertTimeOut(
            'Folder with same name found!',
            'Delete the folder with this file name, and try again.'
          )
          return
        }
        await utilModelsService
          .confirmPromise(
            'File with same name already exists in this folder',
            'Do you want to replace it?'
          )
          .then(async () => {
            uploadFileToServer(target, item, itemParent, duplicate)
          })
          .catch(() => {})
      } else {
        uploadFileToServer(target, item, itemParent, duplicate)
      }
    })
    .catch(() => {
      useIdeStore().setSyncErrorMessages('Failed to verify captcha')
    })
}
/**
 * set the code changed flag so it will tigger code change sync when active item is changed
 */
const setCodeChanged = () => {
  useIdeStore().activeItem.codeChanged = true
  useIdeStore().setCodeUpdated(true)
}
/**
 * Make the given file the home file
 * @param name - The name of the file to make the home file
 * @param onLister - The lister flag
 */
const makeHome = (name: string, onLister: boolean = false) => {
  useIdeStore().project.home = '/' + name
  useIdeStore().setCodeUpdated(true)
  if (!onLister) projectsService.autoSave()
}

/**
 * post find inactive item
 * @param activeItem - The active item
 * @param count - The count of inactive item
 * @returns the inactive item
 */
const postFindInactiveItem = async (activeItem: any, count: number = 0): Promise<any> => {
  if (count > 40) return null
  if (activeItem?.parent && activeItem?.name) {
    const parent = findEdge(activeItem.parent)
    const item = parent.children.find((o: any) => o.name === activeItem.name)
    if (item) {
      // check if the item is inactive
      const currentActiveItem = useIdeStore().activeItem
      if (currentActiveItem?.parent === item.parent && currentActiveItem?.name === item.name) {
        return null
      } else return item
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100))
      return await postFindInactiveItem(activeItem, count + 1)
    }
  } else return null
}
/**
 * set the inactive item
 * @param activeItem - The item to set inactive
 */
const setInactiveItem = async (activeItem: any) => {
  const activeItemName = activeItem.name || null
  const activeItemParent = activeItem.parent || null
  const activeItemContent = activeItem.content || ''

  const inactiveItem = await postFindInactiveItem(activeItem)
  if (inactiveItem) {
    if (
      inactiveItem.name === activeItemName &&
      inactiveItem.parent === activeItemParent &&
      inactiveItem.content !== null
    ) {
      inactiveItem.content = activeItemContent
      inactiveItem.isDirty = true
      inactiveItem?.dirtyActions?.push({ action: SYNC_ACTIONS.FILE_CHANGED })
      useIdeStore().setCodeUpdated(true)
    }
  }
}
/**
 * set the active item
 * @param item - The item to set active
 * @param awaitLiveCodeEmit - The await live code emit flag
 */
const setActiveItem = (item: any, awaitLiveCodeEmit: boolean = false) => {
  if (useIdeStore().isHtml && useIdeStore().isAdvanced) {
    const htmlRe = new RegExp('\\.html$')
    const jsRe = new RegExp('\\.js$')
    const tsRe = new RegExp('\\.ts$')
    const cssRe = new RegExp('\\.css$')
    const reactRe = new RegExp('\\.(j|t)sx?$')
    const vueRe = new RegExp('\\.vue$')
    const php = new RegExp('\\.php$')
    const python = new RegExp('\\.py$')

    if (jsRe.test(item.name)) {
      useIdeStore().codeEditor.getSession().setMode('ace/mode/javascript')
    } else if (cssRe.test(item.name)) {
      useIdeStore().codeEditor.getSession().setMode('ace/mode/css')
    } else if (htmlRe.test(item.name)) {
      useIdeStore().codeEditor.getSession().setMode('ace/mode/html')
    } else if (php.test(item.name)) {
      useIdeStore().codeEditor.getSession().setMode('ace/mode/php')
    } else if (python.test(item.name)) {
      useIdeStore().codeEditor.getSession().setMode('ace/mode/python')
    } else if (vueRe.test(item.name)) {
      useIdeStore().codeEditor.getSession().setMode('ace/mode/vue')
    } else if (reactRe.test(item.name)) {
      useIdeStore().codeEditor.getSession().setMode('ace/mode/typescript')
    } else if (tsRe.test(item.name)) {
      useIdeStore().codeEditor.getSession().setMode('ace/mode/typescript')
    } else {
      useIdeStore().codeEditor.getSession().setMode('ace/mode/javascript')
    }
  }
  useIdeStore().activeItem.content = editorService
    .getEditorSession(IDECONSTANT.CODE_EDITOR)
    .getValue()
  if (!useIdeStore().activeItem.dirtyActions) {
    useIdeStore().activeItem.dirtyActions = []
  }
  if (useIdeStore().activeItem.codeChanged === true) {
    useIdeStore().activeItem.isDirty = true
    useIdeStore().activeItem?.dirtyActions?.push({ action: SYNC_ACTIONS.FILE_CHANGED })
    useIdeStore().activeItem.codeChanged = false
    sync()
  }

  useIdeStore().activeItem = item
  if (useLiveCodeStore().isLiveCodingActive) {
    liveCodingService.disconnectLiveCoding(false, awaitLiveCodeEmit).then(() => {
      editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, '')
      liveCodingService.openSharedFile(false, {
        projectKey: useIdeStore().projectKey,
        name: item.name,
        path: item.parent
      })
    })
  } else {
    if (item.content) {
      editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, item.content)
    } else {
      editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, '')
    }
    useIdeStore().activeItem = item
    if (item.yetToSync) getFileContent(item)
  }
}
// sync utility actions
/**
 * function for sorting the tree items
 * @returns comparator function
 */
const sortComparator = () => {
  return (a: any, b: any) => {
    if (a.children && !b.children) {
      return 1
    } else if (!a.children && b.children) {
      return -1
    }

    return a.name.localeCompare(b.name)
  }
}
/**
 * sort the path
 * @param path - The path to sort
 */
const sortPath = (path: string) => {
  const parent = findEdge(path)
  parent.children.sort(sortComparator())
}
/**
 * update the childrens parent
 * @param item tree item
 */
const updateChildrensParent = (item: any) => {
  for (const child of item.children) {
    if (item.parent === '/') {
      child.parent = '/' + item.name
    } else {
      child.parent = item.parent + '/' + item.name
    }

    if (child.children) {
      updateChildrensParent(child)
    }
  }
}
/**
 * find the edge of the tree
 * @param toPath - The path to the edge
 * @returns The edge of the tree
 */
const findEdge = (toPath: string) => {
  let ancestors = ['']
  if (toPath !== '/') {
    ancestors = toPath.split('/')
  }

  let parent = useIdeStore().project.treeData
  for (let i = 0; i < ancestors.length; i++) {
    if (i === ancestors.length - 1) {
      return parent
    } else {
      parent =
        parent.children[
          parent.children.findIndex((o: any) => {
            return o.name === ancestors[i + 1]
          })
        ]
    }
  }
  return parent
}
/**
 * remove the dirty action from the item. will affect the project from store
 * @param item - The item to remove the dirty action from
 * @param dirtyAction - The dirty action to be removed
 */
const removeDirtyAction = (item: any, dirtyAction: any) => {
  let index = -1
  item.dirtyActions.forEach((element: any, idx: number) => {
    if (element.action === dirtyAction.action) {
      index = idx
    }
  })

  if (index > -1) {
    item.dirtyActions.splice(index, 1)
    useIdeStore().setCodeUpdated(true)
  }

  if (item.dirtyActions.length == 0) {
    item.isDirty = false
  }
}

/**
 * sync the live code
 */
const syncLiveCode = async () => {
  const liveCodeAction: ILiveCodeActions = {
    type: LiveCodeActionsType.SYNC_PROJECT_TREE,
    data: {
      treeData: cloneDeep(useIdeStore().project.treeData),
      home: useIdeStore().project.home
    }
  }
  await liveCodingService.broudcastMessage(liveCodeAction)
}

// sync actions
/**
 * sync a new item with the server
 * @param item - The item to sync
 * @param dirtyAction - The dirty action to be removed
 * @param onLister - on live coding lister
 */
const syncNewItem = async (item: any, dirtyAction: any, onLister: boolean = false) => {
  const syncNewItemRequest: ISyncRequest = {
    id: useIdeStore().isProjectId,
    projectKey: useIdeStore().projectKey,
    path: item.parent,
    name: item.name,
    type: item.children ? 'folder' : 'file'
  }
  await axios
    .post('/api/projectSync/newItem', syncNewItemRequest)
    .then(async () => {
      if (!onLister) await syncLiveCode()
      await removeDirtyAction(item, dirtyAction)
    })
    .catch(() => {
      const parent = findEdge(item.parent)
      parent.children.splice(parent.children.indexOf(item), 1)
      useIdeStore().setSyncErrorMessages('Create Failed!')
    })
}
/**
 * sync a rename with the server
 * @param item - The item to sync
 * @param dirtyAction - The dirty action to be removed
 * @param onLister - on live coding lister
 */
const syncRename = async (item: any, dirtyAction: any, onLister: boolean = false) => {
  const syncRenameRequest: ISyncRequest = {
    id: useIdeStore().isProjectId,
    projectKey: useIdeStore().projectKey,
    path: item.parent,
    name: item.name,
    oldName: dirtyAction.oldName,
    lang: useIdeStore().isLanguage
  }
  await axios
    .post('/api/projectSync/rename', syncRenameRequest)
    .then(async () => {
      if (!onLister) await syncLiveCode()
      else if (onLister && useIdeStore().isAdvanced && useLiveCodeStore().isLiveCodingActive) {
        const isFolder = item?.children?.length > 0 ? true : false
        if (!isFolder) useIdeStore().renameUniqueActiveItemAdvIde(item, dirtyAction)
      }
      removeDirtyAction(item, dirtyAction)
    })
    .catch(() => {
      item.name = dirtyAction.oldName
      useIdeStore().setSyncErrorMessages('Create Failed!')
      removeDirtyAction(item, dirtyAction)
      updateChildrensParent(item)
      sortPath(item.parent)
    })
}

/**
 * get the active item name
 * @returns the active item name
 */
const getActiveItemName = () => {
  const activeItem = useIdeStore().activeItem
  if (activeItem?.parent === '/') {
    return activeItem?.name
  } else {
    return activeItem?.name
  }
}
/**
 * sync a delete with the server
 * @param item - The item to sync
 * @param dirtyAction - The dirty action to be removed
 * @param onLister - on live coding lister
 */
const syncDelete = async (item: any, dirtyAction: any, onLister: boolean = false) => {
  const syncDeleteRequest: ISyncRequest = {
    id: useIdeStore().isProjectId,
    projectKey: useIdeStore().projectKey,
    path: item.parent,
    name: item.name,
    lang: useIdeStore().isLanguage
  }
  await axios
    .post('/api/projectSync/delete', syncDeleteRequest)
    .then(async () => {
      if (!onLister) await syncLiveCode()
      if (onLister) {
        useIdeStore().removeUniqueActiveItemAdvIde(item)
        const activeItemName = getActiveItemName()
        const projectTreeData = useIdeStore().project?.treeData || {}
        if (item.name === activeItemName) {
          if (useIdeStore().uniqueActiveItemAdvIde.length > 0) {
            const nextItem =
              useIdeStore().uniqueActiveItemAdvIde[useIdeStore().uniqueActiveItemAdvIde.length - 1]
            setActiveItem(nextItem, true)
          } else {
            const item = projectTreeData?.children?.find(
              (item: any) => item.name === projectTreeData.slice(1)
            )
            if (item) {
              setActiveItem(item, true)
              useIdeStore().setUniqueActiveItemAdvIde(item)
            }
          }
        }
      }
      const parentEdge = findEdge(item.parent)
      parentEdge.children.splice(parentEdge.children.indexOf(item), 1)
      useIdeStore().setCodeUpdated(true)
    })
    .catch(() => {
      item.markedForDeletion = false
      removeDirtyAction(item, dirtyAction)
      useIdeStore().setSyncErrorMessages('Delete Failed!')
    })
}
/**
 * sync a item move with the server
 * @param item - The item to sync
 * @param dirtyAction - The dirty action to be removed
 * @param onLister - on live coding lister
 */
const syncItemMoved = async (item: any, dirtyAction: any, onLister: boolean = false) => {
  const syncItemMovedRequest: ISyncRequest = {
    id: useIdeStore().isProjectId,
    projectKey: useIdeStore().projectKey,
    toPath: item.parent,
    name: item.name,
    fromPath: dirtyAction.oldPath,
    lang: useIdeStore().isLanguage
  }
  await axios
    .post('/api/projectSync/move', syncItemMovedRequest)
    .then(async () => {
      if (!onLister) await syncLiveCode()
      removeDirtyAction(item, dirtyAction)
    })
    .catch(() => {
      useIdeStore().setSyncErrorMessages('Move Failed!')

      // Move Back
      const oldParent = findEdge(dirtyAction.oldPath)
      const currentParent = findEdge(item.parent)
      currentParent.children.splice(currentParent.children.indexOf(item), 1)
      oldParent.children.push(item)
      item.parent = dirtyAction.oldPath
      sortPath(dirtyAction.oldPath)

      // Reinstansiate old file if any
      if (dirtyAction.replacedItem) {
        currentParent.children.push(dirtyAction.replacedItem)
        sortPath(dirtyAction.replacedItem.parent)
      }
      updateChildrensParent(item)
      removeDirtyAction(item, dirtyAction)
    })
}
/**
 * sync a file change with the server
 * @param item - The item to sync
 * @param dirtyAction - The dirty action to be removed
 */
const trySyncFileChanged = async (item: any, dirtyAction: any) => {
  const syncFileChangeRequest: ISyncRequest = {
    id: useIdeStore().isProjectId,
    projectKey: useIdeStore().projectKey,
    name: item.name,
    parent: item.parent,
    content: item.content,
    lang: useIdeStore().isLanguage
  }
  await recaptchaService
    .callViaCaptcha()
    .then(async () => {
      await axios
        .post('/api/projectSync/updateFile', syncFileChangeRequest)
        .then(async () => {
          removeDirtyAction(item, dirtyAction)
        })
        .catch((error: { response: { status: number } }) => {
          if (error.response.status === 403) {
            useAuthStore().clearRobotCheck()
          }
          useIdeStore().setSyncErrorMessages('Unable to Sync the file with server!')
        })
    })
    .catch((error: any) => {
      useIdeStore().setSyncErrorMessages(error.message || 'Failed to verify captcha')
    })
}
/**
 * get the file content from the server
 * @param item - The item to get the content from
 */
const getFileContent = async (item: any) => {
  const getFileContentRequest: ISyncRequest = {
    id: useIdeStore().isProjectId,
    projectKey: useIdeStore().projectKey,
    name: item.name,
    path: item.parent
  }
  await axios
    .post('/api/projectSync/getFileContent', getFileContentRequest)
    .then(async (response: { data: { content: string } }) => {
      editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, response.data.content)
      item.yetToSync = false
    })
}
/**
 * check if the item is dirty
 * @param items - items to check
 * @returns true if the item is dirty
 */
const dirtyCheck = (items: any) => {
  for (const item of items) {
    if (item.isDirty === true) {
      return false
    }

    if (item.children) {
      if (!dirtyCheck(item.children)) {
        return false
      }
    }
  }
  return true
}
/**
 * sync with the server
 * @param items - item with sync actions
 * @param onLister - on live coding lister
 */
const syncWorker = async (items: any[], onLister: boolean = false) => {
  for (const item of items) {
    if (item.isDirty) {
      if (!item.dirtyActions || item.dirtyActions.length < 1) {
        item.isDirty = false
      }
      for (let i = 0; i < item.dirtyActions.length; i++) {
        if (item.dirtyActions.length == 0) continue
        const dirtyAction = item.dirtyActions[i]

        switch (dirtyAction.action) {
          case SYNC_ACTIONS.NEW_ITEM:
            await syncNewItem(item, dirtyAction, onLister)
            break
          case SYNC_ACTIONS.RENAME:
            await syncRename(item, dirtyAction, onLister)
            break
          case SYNC_ACTIONS.DELETE:
            await syncDelete(item, dirtyAction, onLister)
            break
          case SYNC_ACTIONS.ITEM_MOVED:
            await syncItemMoved(item, dirtyAction, onLister)
            break
          case SYNC_ACTIONS.FILE_CHANGED:
            await trySyncFileChanged(item, dirtyAction)
            break
        }
      }
    }
    if (item.children) {
      await syncWorker(item.children, onLister)
    }
  }
}
/**
 * check for dirty actions
 * @param item - The item to check for dirty actions
 * @returns true if the item has dirty actions
 */
const checkForDirtyActions = (item: any) => {
  if (item.isDirty) {
    return true
  }
  if (item.children) {
    for (const child of item.children) {
      if (checkForDirtyActions(child)) {
        return true
      }
    }
  }
  return false
}
/**
 * post check for dirty actions
 * @param item - The item to check for dirty actions
 * @param count - The count of dirty actions
 * @returns true if the item has dirty actions
 */
const postCheckForDirtyActions = async (item: any, count: number = 0): Promise<boolean> => {
  if (!useIdeStore().isAdvanced) return true
  if (checkForDirtyActions(item)) {
    if (count > 80) return false
    await new Promise((resolve) => setTimeout(resolve, 100))
    return postCheckForDirtyActions(item, count + 1)
  } else return true
}
/**
 * Sync the project
 * @param onAutoSave - The auto save flag
 * @param onLister - The lister flag
 * @param awaitCleanDirtyActions - The await clean dirty actions flag
 */
const sync = async (
  onAutoSave: boolean = false,
  onLister: boolean = false,
  awaitCleanDirtyActions: boolean = false
) => {
  if (useIdeStore().syncInProgress === true) return
  useIdeStore().syncInProgress = true
  await syncWorker(useIdeStore().project.treeData.children, onLister)
  useIdeStore().syncInProgress = false
  if (!onAutoSave && !onLister) {
    if (!awaitCleanDirtyActions) projectsService.autoSave()
    else {
      await postCheckForDirtyActions(useIdeStore().project.treeData).then(
        async (result: boolean) => {
          if (result) {
            await projectsService.autoSave()
          }
        }
      )
    }
  }
}
/**
 * check if the project is synced
 * @returns true if the project is synced
 */
const isSyncSuccess = () => {
  return dirtyCheck(useIdeStore().project.treeData.children)
}
/**
 * sync before execute
 * @param autoSave - The auto save flag
 */
const syncBeforeExecute = async (autoSave: boolean = false) => {
  if (useIdeStore().activeItem.codeChanged === true) {
    useIdeStore().activeItem.isDirty = true
    if (!useIdeStore().activeItem.dirtyActions) {
      useIdeStore().activeItem.dirtyActions = []
    }
    useIdeStore().activeItem?.dirtyActions?.push({ action: SYNC_ACTIONS.FILE_CHANGED })
    useIdeStore().activeItem.content = editorService
      .getEditorSession(IDECONSTANT.CODE_EDITOR)
      .getValue()
    useIdeStore().activeItem.codeChanged = false
  }

  await sync(autoSave)
}

/**
 * @param file clean up each files
 */
const emptyFile = (file: any) => {
  file.isDirty = true
  if (!file.dirtyAction) file.dirtyActions = []
  file.dirtyActions.push({ action: SYNC_ACTIONS.FILE_CHANGED })
  file.content = ''
}

/**
 * @param folder clean up each folder
 */
const extractFiles = async (folder: any) => {
  for (const node of folder.children) {
    const isFolder = node?.children?.length > 0 ? true : false

    if (isFolder) {
      extractFiles(node)
    } else {
      if ('content' in node) {
        emptyFile(node)
      }
    }
  }
}

/**
 *
 * @param root root folder from tree data
 */
const cleanUpFiles = async (root: any) => {
  extractFiles(root)
  await sync()
}

export default {
  uploadFile,
  setCodeChanged,
  makeHome,
  setActiveItem,
  setInactiveItem,
  sortComparator,
  sortPath,
  updateChildrensParent,
  uploadFileToServer,
  findEdge,
  sync,
  isSyncSuccess,
  syncBeforeExecute,
  uploadFileForLiveCodingSync,
  cleanUpFiles
}
