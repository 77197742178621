declare global {
  interface Window {
    dataLayer: any
  }
}
import loadScriptInBody from '@/services/loadScriptInBody.service'
/**
 * injects the gtm script into the body
 */
const initGtm = () => {
  loadScriptInBody.loadScriptInBody('/assets/javascript/gmt.script.js')
}

/**
 * send event to gtm
 * @param data - data to be sent
 * @param count - count
 */
const sentEvent = async (data: any, count: number = 0) => {
  //@ts-ignore
  if (process.env.NODE_ENV === 'development') return
  if (!window.dataLayer) {
    if (count < 20) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      await sentEvent(data, count + 1)
    }
  } else {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ...data })
  }
}
/**
 * this function identifies the user when they log in
 * @param email - user email
 */
const identifyGtm = (email: string) => {
  sentEvent({
    event: 'login',
    email
  })
}

export default { initGtm, identifyGtm, sentEvent }
