import { useAdvanceCompilerStore } from '@/stores/advanceCompiler.store'
import { useAuthStore } from '@/stores/auth.store'
import {
  defaultActiveTabs,
  useIdeStore,
  type ActiveTab,
  type TIdeSettings
} from '@/stores/ide.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import { ADVANCEDIDETYPE, IDECONSTANT, IDERELETIVEHEIGHT, TAB_ICONS_NAMES } from '@/utils/ide'
import { type IMeta } from '@/utils/meta'

import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import projectTreeService from '@/services/ide/projectTree.service'
import projectsService from '@/services/ide/projects.service'
import axios from 'axios'
import { cloneDeep } from 'lodash-es'
import liveCodingService from './liveCoding.service'

export interface IInitIdeResponse {
  ideSettings: TIdeSettings | null
  inputFiles: Array<string>
  robotChecked: boolean
}
export interface IInitAdvancedIdeRequest {
  lang: string | null
  type: (typeof ADVANCEDIDETYPE)[keyof typeof ADVANCEDIDETYPE]
  projectKey: string | boolean
  projectId: string
  shareId: string
  isInstant: object
}
export interface IInitAdvancedIdeResponse {
  project: any
  projectKey: string | boolean
  homeFile?: any
}
export interface IInitTerminalRequest {
  lang: string | null
}
/**
 * Initialize the advanced ide
 * @param type - The type of advanced ide
 */
const initAdvancedIde = async (type: (typeof ADVANCEDIDETYPE)[keyof typeof ADVANCEDIDETYPE]) => {
  const initAdvancedIdeRequest: IInitAdvancedIdeRequest = {
    lang: useIdeStore().isLanguage,
    type: type,
    projectKey: useIdeStore().projectKey,
    projectId: useIdeStore().isProjectId,
    shareId: useIdeStore().isShareId as string,
    isInstant: useIdeStore().isInstantShare
  }
  await axios
    .post('/api/projectSync/initIde', initAdvancedIdeRequest)
    .then((response: { data: IInitAdvancedIdeResponse }) => {
      if (useAdvanceCompilerStore().projectKeyAdvCompiler == null) {
        useIdeStore().setProjectKey(response.data.projectKey)
      }

      if (type !== ADVANCEDIDETYPE.OPEN && type !== ADVANCEDIDETYPE.OPENSHARE) {
        useIdeStore().setProject(response.data.project)
        useIdeStore().setActiveItem()
        if (type === ADVANCEDIDETYPE.CLEAR && useJdroidStore().startCoadingEmpty) {
          projectTreeService.cleanUpFiles(useIdeStore().project.treeData)
        }
      }
      if (
        (type === ADVANCEDIDETYPE.OPEN && liveCodingService.isLiveCodingNoActive()) ||
        type === ADVANCEDIDETYPE.OPENSHARE
      ) {
        editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, response.data.homeFile)
        useIdeStore().setCodeUpdated(false)
      }
    })
    .catch((error) => {
      if (error) useIdeStore().setCodeUpdated(false)
    })
}
/**
 * Clear the ide settings
 */
const clearIdeSettings = () => {
  useIdeStore().setIdeSettings(null, false)
}
/**
 * get the ide settings
 * @returns The response object
 */
const initIdeSettings = async () => {
  return await axios
    .post('/api/ide-setting/view')
    .then((response) => {
      useIdeStore().setIdeSettings(response.data?.ideSetting, true)
    })
    .catch(() => {
      useIdeStore().setIdeSettings(null, false)
    })
}
/**
 * Initialize the ide when ide view is mounted
 */
const initIde = async () => {
  await axios.post('/api/doodle/initIde').then((response: { data: IInitIdeResponse }) => {
    useIdeStore().setInputFiles(response.data.inputFiles)
    useIdeStore().setIdeSettings(response.data.ideSettings, true)
    if (response.data.robotChecked) {
      useAuthStore().robotCheckDone()
    }
  })
}

/**
 * @param req The request object
 * @returns The response object
 */
const updateIdeSetting = async (req: Object) => {
  return await axios
    .post('/api/ide-setting/update', req)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error.response
    })
}

/**
 * Initialize the ide everytime the route changes
 * @param meta - The meta to set
 */
const initOnRouterChange = async (meta: IMeta) => {
  await cleanIde()
  useIdeStore().setProject(null)
  useIdeStore().setRouteMeta(meta)
  editorService.injectAceAndSplit()
  if (meta.language === 'blockly') blocklyService.loadBlockly()
  editorService.resetCodeEditor()

  useIdeStore().setIdeMeta(meta)

  if (useIdeStore().isAdvanced && !useIdeStore().isAdvancedCompilerDatabase) {
    await initAdvancedIde(ADVANCEDIDETYPE.INIT)
  }
}
/**
 * Clean the ide when ide view is unmounted
 */
const cleanIde = () => {
  if (useIdeStore().codeEditor) {
    useIdeStore().codeEditor.destroy()
    useIdeStore().codeEditor = null
  }
  if (useIdeStore().outputEditor) {
    useIdeStore().outputEditor.destroy()
    useIdeStore().outputEditor = null
  }
  if (useIdeStore().projectEditor) {
    useIdeStore().projectEditor.destroy()
    useIdeStore().projectEditor = null
  }
  if (useIdeStore().executeCodeEditor) {
    useIdeStore().executeCodeEditor.destroy()
    useIdeStore().executeCodeEditor = null
  }
  if (useIdeStore().executeOutputEditor) {
    useIdeStore().executeOutputEditor.destroy()
    useIdeStore().executeOutputEditor = null
  }
  if (useIdeStore().copyEditor) {
    useIdeStore().copyEditor.destroy()
    useIdeStore().copyEditor = null
  }
  if (useIdeStore().downloadEditor) {
    useIdeStore().downloadEditor.destroy()
    useIdeStore().downloadEditor = null
  }
  if (useIdeStore().openEditor) {
    useIdeStore().openEditor.destroy()
    useIdeStore().openEditor = null
  }
  if (useIdeStore().htmlDoctypeEditor) {
    useIdeStore().htmlDoctypeEditor.destroy()
    useIdeStore().htmlDoctypeEditor = null
  }
  if (useIdeStore().htmlHeadEditor) {
    useIdeStore().htmlHeadEditor.destroy()
    useIdeStore().htmlHeadEditor = null
  }
  if (useIdeStore().htmlBodyEditor) {
    useIdeStore().htmlBodyEditor.destroy()
    useIdeStore().htmlBodyEditor = null
  }
  if (useIdeStore().htmlJsEditor) {
    useIdeStore().htmlJsEditor.destroy()
    useIdeStore().htmlJsEditor = null
  }
  if (useIdeStore().htmlCssEditor) {
    useIdeStore().htmlCssEditor.destroy()
    useIdeStore().htmlCssEditor = null
  }

  if (useIdeStore().isBlockly) blocklyService.unloadBlockly()

  editorService.ejectAce()
  useIdeStore().setCodeUpdated(false)
  useIdeStore().aiGenerateCodeReq = ''
  useIdeStore().aiGenerateCodeRes = ''
}

/**
 * Initialize the  html ide everytime the route changes
 */
const initHtmlOnRouterChange = async () => {
  if (!useIdeStore().isShared) {
    await new Promise((resolve) => setTimeout(resolve, 500))
    await editorService.initEditors()
    useIdeStore().setCodeUpdated(false)
    projectsService.initOnRouterChange()
  }
}

/**
 * init the post ide
 * @param byPost - router query
 */
const initPostIde = async (byPost: boolean) => {
  if (!byPost) return
  await axios.post('/api/doodle/initPostIde').then((response: { data: { script: string } }) => {
    editorService.postSetScript(response.data.script)
    useIdeStore().setCodeUpdated(false)
  })
}

/**
 * Clean the ide store
 */
const cleanIdeStore = () => {
  const saved = cloneDeep(useIdeStore().activeTabPosition)
  if (useIdeStore().openProjectID) {
    const openProjectID = useIdeStore().openProjectID
    useIdeStore().$reset()
    useIdeStore().setOpenProjectID(openProjectID)
  } else {
    useIdeStore().$reset()
  }
  useIdeStore().syncActiveTabPosition(saved)
}

/**
 * Set dark mode
 */
const setMode = () => {
  const themeVal: string | null = localStorage.getItem('jdoodle-mode') || null
  setModeClass(themeVal, true, false)
  useIdeStore().setColorMode(themeVal)
}

/**
 * Set mode class on id app
 * @param val mode value
 * @param isSetStorage whether set local storage or not
 * @param isTerminal whether is terminal editor
 */
const setModeClass = (val: string | null, isSetStorage: boolean, isTerminal: boolean) => {
  const appElement = document.getElementById('app')
  if (appElement && val) {
    if (val == 'dark') {
      appElement.classList.add('dark')
    } else {
      appElement.classList.remove('dark')
    }

    if (!isTerminal) editorService.codeEditorsSetTheme()

    if (isSetStorage) localStorage.setItem('jdoodle-mode', val == 'dark' ? 'dark' : 'light')
  }
}

/**
 * Remove dark class on id app
 */
const removeDarkClass = () => {
  document.getElementById('app')?.classList.remove('dark')
}
/**
 * Height change function
 * @param height The height
 */
const heightChangeFunction = (height: number | null = null) => {
  const ideComp = document.getElementById(IDECONSTANT.IDEHOMEVIEW)
  const minimumHeight = document.getElementById(IDERELETIVEHEIGHT.MINIMUMID)?.clientHeight || 0

  if (!ideComp) return
  if (height == minimumHeight && useIdeStore().currentMobileTab == null) {
    ideComp?.style.setProperty('min-height', `${height}px`)
  } else {
    if (useIdeStore().currentMobileTab != null && height) {
      ideComp?.style.setProperty('max-height', `${height - 2}px`)
    } else {
      ideComp?.style.setProperty('max-height', `${height}px`)
    }
  }
}

/**
 * update tab positions in db when user logged in
 * @param requestData the request date to update in db
 */
const updateTabPositions = async (requestData: ActiveTab[]): Promise<void> => {
  await axios.post('/api/user-config/idetabs', { tabs: requestData })
}
/**
 * get tab positions from db when user logged in
 * @returns activeTab Array
 */
const getTabPositions = async () => {
  useIdeStore().setIsTabDateFromApi(true)
  try {
    const response = await axios.get('/api/user-config/idetabs')
    return (response.data?.tabs ??
      (response.data?.length > 0 ? response.data : defaultActiveTabs)) as ActiveTab[]
  } catch (error) {
    return [] // Return an empty array in case of an error
  }
  // await axios.get('/api/user-config/idetabs').then((response) => {
  //   return response.data as ActiveTab[]

  // })
}

/**
 * set tabs position to local storage
 * @param tabs tabs current positions
 * @param isDataBaseRoute isDataBaseRoute route value
 * @param isUserloggedIn isUserloggedIn value
 */
const setTabPosition = async (
  tabs: ActiveTab[],
  isDataBaseRoute: boolean,
  isUserloggedIn: boolean
) => {
  let values: ActiveTab[]

  // Retrieve existing tab positions
  if (isUserloggedIn) {
    values = ((await getTabPositions()) as ActiveTab[]) || []
  } else {
    values = (getTabPositionFromLocalStorage() as ActiveTab[]) || []
  }

  values = Array.isArray(values) ? values : []

  const newTabs: ActiveTab[] = []

  if (isDataBaseRoute) {
    // Filter out DB_TERMINAL and Jdroid from existing values
    const filteredValues = values?.filter(
      (item) =>
        item.tabName != TAB_ICONS_NAMES.DB_TERMINAL && item.tabName != TAB_ICONS_NAMES.JDROID
    )
    newTabs.push(...filteredValues)

    // Add the DB_TERMINAL Terminal and Jdroid tabs from the current tabs
    const browserTab = tabs?.find((item) => item.tabName == TAB_ICONS_NAMES.DB_TERMINAL)
    const jdroidTab = tabs?.find((item) => item.tabName == TAB_ICONS_NAMES.JDROID)

    if (browserTab) newTabs.push(browserTab)
    if (jdroidTab) newTabs.push(jdroidTab)
  } else {
    // Handle non-Database route case
    const filteredValues = values?.filter((item) => item.tabName == TAB_ICONS_NAMES.DB_TERMINAL)
    newTabs.push(...filteredValues)

    // Add non-Database and non-Jdroid tabs
    const nonDatabaseTab = tabs?.filter((item) => item.tabName != TAB_ICONS_NAMES.DB_TERMINAL)
    newTabs.push(...nonDatabaseTab)
  }

  // Store the updated tabs
  if (isUserloggedIn) {
    updateTabPositions(newTabs)
  } else {
    localStorage.setItem('ide.activeTabPosition', JSON.stringify(newTabs))
  }

  editorService.resizeCodeEditor()
}
/**
 * @returns return parsed values from localstorage
 */
const getTabPositionFromLocalStorage = () => {
  const getTabPositionsValuesFromLocalStorage = localStorage.getItem('ide.activeTabPosition')
  let parsedTabPositions = null
  if (getTabPositionsValuesFromLocalStorage) {
    try {
      parsedTabPositions = JSON.parse(getTabPositionsValuesFromLocalStorage)
      if (
        typeof parsedTabPositions === 'object' &&
        Object.prototype.hasOwnProperty.call(parsedTabPositions, 'activeTab')
      ) {
        return parsedTabPositions?.activeTab as ActiveTab[]
      } else {
        return parsedTabPositions as ActiveTab[]
      }
    } catch (e) {
      return []
    }
  }
}

export default {
  cleanIde,
  initOnRouterChange,
  initHtmlOnRouterChange,
  initPostIde,
  clearIdeSettings,
  initIdeSettings,
  initIde,
  initAdvancedIde,
  cleanIdeStore,
  updateIdeSetting,
  setMode,
  setModeClass,
  removeDarkClass,
  heightChangeFunction,
  updateTabPositions,
  getTabPositions,
  setTabPosition,
  getTabPositionFromLocalStorage
}
